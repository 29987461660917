import React, { Fragment } from 'react'
import ReactDOM from "react-dom";
import { Route, Router } from 'react-router-dom'
import Contactus from './View/Component/Contactus'
import HeaderCom from './View/Component/HeaderCom'
import NavbarCom from './View/Component/NavbarCom'
import Registered from './View/Component/Registered';
import Home from './View/Frontend/Home'

function App() {
  return (
   <Fragment>
  {/* <Registered /> */}
   </Fragment>
  )
}

export default App