import React from 'react'
import timeIcon from '../Assets/Images/timeIcon.png'
import { Col, Container, Row, Button, CardBody, Card } from 'reactstrap'

function CardItem(props)
 {
  return (
    <div>
        <Card className='border-rad shadow'>
  <CardBody>
    <div className='text-center'>
<img src={props.image} alt='time-icon' width="100%" height={120}/>
<p className='time-icon pt-3'>{props.name}</p>
    </div>
    <div>
      <p className='time-text'>{props.desc}</p>
    </div>
  </CardBody>
</Card>
    </div>
  )
}

export default CardItem
