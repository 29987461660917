import React, { Component } from 'react'
import userIcon from '../Assets/Images/userIcon.png'
import phoneIcon from '../Assets/Images/phoneIcon.png'
import { Fragment } from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Container, Row, Col, NavbarText, Button
} from 'reactstrap';

export default class NavbarCom extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false
    };
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  render() {
    return (
      <div>

        <Navbar light expand="md">
          <NavbarBrand href="/" className='nav-logo blue-color ms-5 pl-5'>EasyConnect</NavbarBrand>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar className='justify-content-around'>
            <Nav className="mr-auto text-center" navbar>
              <NavItem className='ms-5'>
                <NavLink href="/about" className='nav-linktext '>
                  <span className='borderb'>About us</span>
                </NavLink>
              </NavItem>
              <NavItem className='ms-4'>
                <NavLink href="/" className='nav-linktext '>
                  <span className='borderb'>How it Works</span>
                </NavLink>
              </NavItem>
              <NavItem className='ms-4'>
                <NavLink href="/faqs" className='nav-linktext '>
                  <span className='borderb'>FAQs</span>
                </NavLink>
              </NavItem>
              {/* <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                Options
                </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem>
                  Option 1
                  </DropdownItem>
                <DropdownItem>
                  Option 2
                  </DropdownItem>
                <DropdownItem divider />
                <DropdownItem>
                  Reset
                  </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown> */}
            </Nav>
            <Nav>

              <NavbarText className='mt-2'>
                <NavLink href="/login" className='nav-linktext text-color'>
                  <img src={userIcon} alt='usericon' width={15} /><span className='borderb ms-2'>Partner Log in</span>
                </NavLink>
              </NavbarText>
              <NavbarText className=''>
                <NavLink href="/contact" className='nav-linktext ms-3'>
                  <Button className='blue-background navbar-button '>
                    <img src={phoneIcon} alt='phone-icon' width={15} />
                    {/* +180 5474 645 */}
                  </Button>
                </NavLink>
              </NavbarText>
            </Nav>
          </Collapse>
        </Navbar>

      </div>
    )
  }
}
