import React from 'react'
import { Link } from 'react-router-dom'
import { Col, Container, Row, Form, FormGroup, Input,Button } from 'reactstrap'
import arrow from '../Assets/Images/arrow.png'

function Registered() {
  return (
    <div>
      <section className='contact-background py-5'>
        <Container>
          <Row>
            <Col md={12} sm={12}>
              <h1 className='contact-heading py-4'>Register</h1>
              <p className='register-paragrah text-center'>Sign up below to become a referral partner for Easy Connect</p>
            </Col>
          </Row>
          <Form>
            <Row>
              <Col md={4} sm={12}>
                <FormGroup className='shadow'>
                  <Input type="text" name="email" placeholder="First name" className='register-border' />
                </FormGroup>
              </Col>
              <Col md={4} sm={12}>
                <FormGroup className='shadow'>
                  <Input type="text" name="email" placeholder="Last name" className='register-border' />
                </FormGroup>
              </Col>
              <Col md={4} sm={12}>
                <FormGroup className='shadow'>
                  <Input type="text" name="email" placeholder="Company name" className='register-border' />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6} sm={12}>
                <FormGroup className='shadow'>
                  <Input type="text" name="email" placeholder="Enter email address" className='register-border' />
                </FormGroup>
              </Col>
              <Col md={6} sm={12}>
                <FormGroup className='shadow '>
                  <Input type="number" name="email" placeholder="Enter Phone number" className='register-border' />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={12} sm={12}>
                <FormGroup className='shadow'>
                  <Input type="textarea" row="5" name="text" id="exampleText" className='textarea-sy register-border' placeholder='Let’s talk now!' />
                </FormGroup>
              </Col>
            </Row>
            <div className=' text-center'>
              <Button className='all-btn shadow'>Create an account <img src={arrow} alt='arrow' className='foterarrow-btn' /> </Button>
            </div>
            <Row className='justify-content-center'>
                                        <Col md={12} sm={12}>
                                            <div >
                                                <p className='text-center d-block'>All
                                                Ready registered? <Link to="/login" className='login-lin'>Login Here</Link></p>
                                            </div>
                                        </Col>
                                    </Row>
          </Form>
        </Container>
      </section>
    </div>
  )
}

export default Registered