import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import HeaderCom from '../Component/HeaderCom'
import Contactus from '../Component/Contactus'

function Aboutus() {
    return (
        <div>
            <HeaderCom
                name="About us"
                leftname="Home"
                rightname="ABOUT US"
            // secnme="jiya"
            />
<section className='bg-white about-section'>
            <Container className='py-4'>
                <Row>
                    <Col md={12} sm={12}>
                        <div>
                            <h1 className='about-headingtop py-3'>Did you know people rate moving as more stressful than a divorce?
                            </h1>
                            <p className='text-justify about-textpar'>According to a recent survey 65% of people listed moving as one of the top 3 most stressful events in their life. <br />
                            <br /> 
                                After moving 8 times in 7 years, our founder was one of those people fed up with the 1 hour hold times on the phone, comparing prices from multiple vendors, pushy sales people, email spam and all the while trying to make sure the move itself went smooth. There was also the relocation of mail services, searching for a home in the first place and trying to get everything done on schedule. There had to be a better way. <br />
                                <br />
                                Easy Connect was founded to help people during this crazy time. With our friendly customer service team on call whenever you need (including weekends), our aim is to help make your move the smoothest event you've experienced and really act as your personal concierge (no tipping necessary). <br />
                                <br />
                                We do all the "dirty" work; comparing prices, entering information, meeting timelines and organizing everything from postal forwarding to getting moving quotes without spamming your email box with hundreds of offers or putting you on hold forever.
                                <br /> <br />
                                We present you truly unbiased offers from internet providers, moving companies and others. We we are a 100% independently owned and operated US based company, never pushing you towards any one supplier but letting you choose what best suits your needs. As our motto says, "you select, we connect".
                                <br /> <br />
                                Our one stop service lets you go through just one point of contact to organize everything you will need for your new home.
                                We hope to help you soon</p>
                        </div>
                    </Col>
                </Row>
            </Container>
            </section>
            <Contactus />
        </div>
    )
}

export default Aboutus