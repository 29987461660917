import React from 'react'
import { Container, Row, Col, Button } from 'reactstrap'
import arrow from '../Assets/Images/arrow.png'
import twitterIcon from '../Assets/Images/twitterIcon.png'
import facebookIcon from '../Assets/Images/facebookIcon.png'
import linkdinIcon from '../Assets/Images/linkdinIcon.png'
import { Link } from 'react-router-dom'

function FotterCom() {
    return (
        <div>
           <section className='bg-white'>
           <Container className='py-5'>
                <Row className='foter-border py-4'>
                    <Col md={6} sm={6}>
                        <h1 className='footer-heading'>EasyConnect</h1>
                    </Col>
                    <Col md={6} sm={6}>
                        <div className=' text-end'>
                            <Button className='all-btn shadow'>Connect me now <img src={arrow} alt='arrow' className='foterarrow-btn' /> </Button>
                        </div>
                    </Col>
                </Row>
                <Row className='pt-5'>
                    <Col md={5} sm={12}>
                        <p className='foterHeading-one'>Have any questions?
                            Feel free to email or call. </p>
                        <p className='foterContact-text'>Contact us</p>
                        <Row className='foter-border py-2'>
                            <Col md={6} sm={6}>
                                <p className='foterContact-number'> +44 0870 5576 00 </p>
                            </Col>
                            <Col md={6} sm={6}>
                                <p className='foterContact-number'>info@easyconnect.com</p>
                            </Col>
                        </Row>
                        <Row className='pt-4'>
                            <Col md={4} sm={4} className="cards-pad">
                                <div>
                                    <img src={twitterIcon} alt='twitter-icon' className='footer-twiter' /> <a href='#' className='footerTwiter-text ms-2'>Twitter</a>
                                </div>
                            </Col>
                            <Col md={4} sm={4} className="cards-pad">
                                <div>
                                    <img src={facebookIcon} alt='facebook-icon' className='footer-twiter' /> <a href='#' className='footerTwiter-text ms-2'>Facebook</a>
                                </div>
                            </Col>
                            <Col md={4} sm={4} className="cards-pad">
                                <div>
                                    <img src={linkdinIcon} alt='linkdin-icon' className='footer-twiter' /> <a href='#' className='footerTwiter-text ms-2'>Linkedin</a>
                                </div>
                            </Col>
                        </Row>

                    </Col>
                    <Col md={3} sm={12}>
                        <div className='text-center'>
                            <p className='fotterCompany-text'>Company</p>
                            <ul className='fotter-unorder'>
                                <li><a href='/about' className='footer-anker'>About us </a> </li>
                                <li><a href='/faqs' className='footer-anker'>FAQ</a></li>
                                <li><a href='/login' className='footer-anker'>Partner Login</a></li>
                            </ul>
                        </div>
                    </Col>
                    <Col md={4} sm={12}>
                    <div className='text-center'>
                            <p className='fotterCompany-text'>Application</p>
                            <ul className='fotter-unorder'>
                                <li><a href='/privacy' className='footer-anker'>Privacy Policy</a> </li>
                                <li><a href='/terms' className='footer-anker'>Terms & Conditions</a></li>
                              
                            </ul>
                        </div>
                    </Col>
                </Row>
            </Container>
           </section>
        </div>
    )
}

export default FotterCom