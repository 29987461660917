import React from 'react'
import { Accordion, AccordionBody, AccordionHeader, AccordionItem ,UncontrolledAccordion} from 'reactstrap'

function Collapseup(props) {
  return (
    <div>
<div  className='collapseOnee pt-2 shadow'>
  <UncontrolledAccordion defaultOpen="1">
  
    <AccordionItem>
      <AccordionHeader targetId="3">
      <span className='one'>{props.number}</span>
      <span className='ms-4'> {props.name}</span>
      </AccordionHeader>
      <AccordionBody accordionId="3">
     {props.desc}
      </AccordionBody>
    </AccordionItem>
  </UncontrolledAccordion>
</div>
    </div>
  )
}

export default Collapseup