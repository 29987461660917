import React from 'react'
import { Col, Container, Row } from 'reactstrap'
import Contactus from '../Component/Contactus'
import HeaderCom from '../Component/HeaderCom'
import Registered from '../Component/Registered'

function Pattner() {
    return (
        <div>
            <HeaderCom
                name="Partner with us"
                secname=""
                date=""
                leftname="Home"
                rightname="PARTNER"
            />
            <Container className='py-5'>
                <Row>
                    <Col md={12} sm={12}>
                        <div>
                            <h1 className='privacy-heading'>Why Partner with us?</h1>
                            <p className='privicy-paragrah'>
                                If you are serious about the service you provide as a professional in the real estate industry, then you know customer satisfaction is what drives the foundation of any successful company or agency. Being successful in real estate requires partnerships and service that last beyond just a buying/selling/renting transaction.
                                <br /> <br />
                                We partner with professionals such as yourself to complete the client experience and make you the center of attention. Our passion is serving the clients of our partners, while helping all parties win. A true triple play.
                            </p>
                            <h1 className='privacy-heading'>What benefits do you get?</h1>
                            <p className='privicy-paragrah'>

                                <ul>
                                    <li>  We service your clients on your behalf: Our dedicated customer success team, based in the US, helps your clients during their move. We forward addresses from the post office, sign up for utilities such as internet, electricity and help them with the move itself. We are a free service to them and help save them time and money on the most stressful event most people experience in their lives. We always put customer service number 1 on our list and make sure every customer knows YOU are the main force behind us providing this service to them. </li> <br />
                                    <li> We save you (and your client ) time: As a real estate professional, your main job is serving the needs of people looking to buy, sell and rent. Imagine how much time you would take out of your day to organize all the things your clients need when they are moving? Let us do the hard work and service them, while saving you time, saving their time and letting you take all the credit.</li> <br />
                                    <li>Customer service really pays: And we mean literally. For every successful connection that we make for your clients, we will remit a commission to you. If you are referring clients on a regular basis, the extra revenue stream for your business is nothing to sneeze at. </li> <br />   
                                    <li>
                                        We surprise and delight: We have competitions, prizes and giveaways regularly. Whether that is paying an extra bonus to your employees as part of a competition, sending local business vouchers to keep them happy or randomly paying for your client's dinner through an Uber gift card, we like to keep people on their toes. And by toes, we mean the happy feet kind.
                                    </li>
                                </ul>
                                Why wait? Click on the partner registration button and sign up today and let us partner with you to grow your business.
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Registered />
        </div>
    )
}

export default Pattner