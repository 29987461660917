import React from 'react'
import { Col, Container, Row, Button, CardBody, Card } from 'reactstrap'
import hometop from '../Assets/Images/hometop.png'
import arrow from '../Assets/Images/arrow.png'
import timeIcon from '../Assets/Images/timeIcon.png'
import CardItem from '../Component/CardItem'
import moneyIcon from '../Assets/Images/moneyIcon.png'
import settingIcon from '../Assets/Images/settingIcon.png'
import Frame from '../Assets/Images/Frame.png'
import partnerOne from '../Assets/Images/partnerOne.png'
import pattnerTwo from '../Assets/Images/pattnerTwo.png'
import pattnerThree from '../Assets/Images/pattnerThree.png'
import pattnerFour from '../Assets/Images/pattnerFour.png'
import { Link } from 'react-router-dom'
// import asaan from '../Assets/Images/asaan.jpg'
import ra1 from '../Assets/Images/ra1.png'
import ra2 from '../Assets/Images/ra2.png'
// import asaan2 from '../Assets/Images/asaan2.png'
import assan4 from '../Assets/Images/assan4.png'

function Home() {
  return (
    <div >
      <Container className='py-5'>
        <Row className=''>
          <Col md={6} sm={6}>
            <div className='pt-5'>
              <h1 className='hometop-heading '>Moving is hard. <br />
                We make it simple, <br />
                convenient and easy</h1>
              <p className='hometop-text py-3'>We set up all your home utilities, mail forwarding, and other time consuming moving tasks for you in one easy step, for free.
                <div className='pt-5'>
                 <Link to="/pattner">
                 <Button className='all-btn shadow light-bl'>Connect me now <img src={arrow} alt='arrow' className='foterarrow-btn' /> </Button>
                 </Link>
                  {/* <Button className='fotter-btnt btn-outline-light ms-4 light-bl shadow'>Try For Free <img src={arrow} alt='arrow' className='foterarrow-btn' /> </Button> */}
                </div>
              </p>
            </div>
          </Col>
          <Col md={6} sm={6}>
            <div>
              <img src={hometop} alt='hometop' width="100%" />
            </div>
          </Col>
        </Row>
        <Row className='py-5 justify-content-center'>
          <Col md={9} sm={12}>
            <div className='text-center py-5 '>
              <Card className='shadow homeCard'>
                <CardBody >
                  <p className='cardText'>Got time for a coffee break? That's how long it takes to set up your utilities <br /> with us. Your personal concierge will spend about <b>15 minutes</b> with you <br /> discussing your moving needs and offers. After that, we get to work <br /> connecting your utilities so you can get back to the important things in life. <br /> Our service is second to none and <b>always free</b> (<span className='text-warning'>click here</span> to see how).</p>
                </CardBody>
              </Card>
            </div>
          </Col>
        </Row>

        {/* 2nd Part */}

        <section>
          <Row>
            <Col md={12} sm={12}>
              <p className='cardTop-text'>We Save You <br />
                Time, Money and Your Sanity</p>
              <p className='cardtop-textine'>We're with you every step of your move</p>
            </Col>
          </Row>
          <Row className='pt-5'>
            <Col md={4} sm={12} className="cards-pad">

              <Card className='border-rad blue-obrder'>
                <CardBody>
                  <div className='text-center'>
                    <img src={timeIcon} alt='time-icon' width={110} height={110} />
                    <p className='time-icon'>Time</p>
                  </div>
                  <div>
                    <p className='time-text'>Really want to spend hours on the phone and internet researching the best utilities and organizing  for your new place? We save you hours on your move and years of sanity.</p>
                  </div>
                </CardBody>
              </Card>

              {/* <CardItem
              image={timeIcon}
               name="Time"
                desc="Really want to spend hours on the phone and internet researching the best utilities and organizing  for your new place? We save you hours on your move and years of sanity."
               /> */}
            </Col>
            <Col md={4} sm={12} className="cards-pad">

              <Card className='border-rad pink-border'>
                <CardBody>
                  <div className='text-center'>
                    <img src={moneyIcon} alt='time-icon' width={110} height={110} />
                    <p className='time-icon'>Money</p>
                  </div>
                  <div>
                    <p className='time-text'>We work with all the major utility providers . We always have the best deals on tap so you can save. Internet, TV, power or moving companies, we 've got you covered.</p>
                  </div>
                </CardBody>
              </Card>


              {/* <CardItem className="pink-border"
                image={moneyIcon}
                name="Money"
                desc="We work with all the major utility providers . We always have the best deals on tap so you can save. Internet, TV, power or moving companies, we 've got you covered."
              /> */}
            </Col>
            <Col md={4} sm={12} className="cards-pad">
              <Card className='border-rad yellow-color'>
                <CardBody>
                  <div className='text-center'>
                    <img src={settingIcon} alt='time-icon' width={110} height={110} />
                    <p className='time-icon'>Independent and local</p>
                  </div>
                  <div>
                    <p className='time-text'>We are not tied to any one provider, ensuring you always get the best deals . And our local based in the USA, not an offshore call center, for  the best local knowledge and service.</p>
                  </div>
                </CardBody>
              </Card>

              {/* <CardItem
                name="Independent and local"
                image={settingIcon}
                desc="We are not tied to any one provider, ensuring you always get the best deals . And our local based in the USA, not an offshore call center, for  the best local knowledge and service."
              /> */}
            </Col>
          </Row>
        </section>
        {/* 3rd part */}
      </Container>
      <section className='frame-section '>
        <Container fluid className='px-0 mx-0'>
          <Row className='new-frame-text'>
            <Col md={12} sm={12} className="farm-bot rahe">
              <p className='frame-text pt-5 formtext-new'>How it works</p>
              <p className='frame-textt'>Give us a call and we'll do it all</p>
            </Col>
          </Row>
        <Row>
            <Col md={12} sm={12}>
              <div>
                <img src={assan4} alt='frame' width="100%" className='frameimg' />
              </div>
            </Col>
          </Row> 

        </Container>
      </section>

      {/* Pattner Section */}
      <section className='bg-white py-5' >
        <Container className='patner-section pt-2'>
          <Row>
            <Col md={12} sm={12}>
              <div>
                <p className='patner-heading'>Our Supplier Partners</p>
                <p className='cardtop-textine py-3'>We work with all the major suppliers in every vertical.</p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={3} sm={12} className="cards-pad">
              <CardItem
                image={ra1}
                name="Internet & TV"
                desc="We find the best plans and service available to you in your home. Higher , faster speed, more channels, better deals."
              />
            </Col>
            <Col md={3} sm={12} className="cards-pad">
              <CardItem
                image={ra2}
                name="Utilities"
                desc="Sign up for electric, water, and gas. Go green, find discounts, get flexible terms or locked in rates"
              />
            </Col>
            <Col md={3} sm={12} className="cards-pad">
              <CardItem
                image={pattnerThree}
                name="Moving"
                desc="Compare and reserve moving companies nationwide. Sort by price, service level or both. Let us get spammed or ripped off."
              />
            </Col>
            <Col md={3} sm={12} className="cards-pad">
              <CardItem
                image={pattnerFour}
                name="Address Updates"
                desc="Notify all the right folks eserve moving of your new address. Forward your mail, change your DMV details."
              />
            </Col>
          </Row>

        </Container>
      </section>
      {/*  */}
      <section className='py-5'>
        <Container>
          <Row className='justify-content-center'>
            <Col md={11} sm={12}>
              <div className=' py-5 '>
                <Card className='shadow p-3 blue-obrder homeCard'>
                  <CardBody >
                    <h1>Become a referral partner</h1>
                    <p>Does your business deal with people that are in the process of moving home? Maybe you’re a real estate agent, title company/legal professional or a property manager. <br />
                      If so, we can make you look like a rock star in the eyes of your clients. Easy Connect helps your clients connect their utilities at their new address completely   free of charge. <br />
                      By partnering with us you will not only make their lives easier, you will add considerable value to your client experience and keep them coming back for more whenever they   plan their next real estate journey. <br />
                     <span className='text-warning'> <b>Click here</b> </span> to see the benefits of referring your clients to us and how to become  a referral partner.</p>
                    <Link to="/pattner">
                    <Button className='all-btn shadow'>Get Started Now <img src={arrow} alt='arrow' className='foterarrow-btn' /> </Button>
                    </Link>
                  </CardBody>
                </Card>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  )
}

export default Home