import React from 'react'
import { Col, Container, Row } from 'reactstrap'
import HeaderCom from '../Component/HeaderCom'

function TermCondition() {
    return (
        <div>
            <HeaderCom
                name="Terms of Use"
                secname=""
                date="Last update: 12 January 2022"
                leftname="Home"
                rightname="TERMS OF USE"
            />
            <Container className='py-5'>
                <Row>
                    <Col md={12} sm={12}>
                        <div>
                            <h1 className='privacy-heading'>Terms of Use</h1>
                            <p className='privicy-paragrah'>
                                This site, and/or associated technology is operated by Easy Connect LLC, a New York company registered in the United States of America. By using this site and/or associated technology, you agree to the following Terms & Conditions (“Terms”) with Easy Connect. These Terms apply to all and any use of our service. “Using” our service includes, but is not limited to, registering an account, contacting, or utilizing any third-party service providers through this site, browsing web pages, viewing articles, or any uses via mobile applications, mobile devices, personal computers, email, or other electronic devices, submitting or lodging a request for services on behalf of a client. If you do not accept these Terms, you may not use this service.
                                <br /> <br />
                                In our sole discretion, we may revise these Terms from time to time. Your continued use of this site or any service after such updates means that you agree with the revised Terms. We will make reasonable efforts to notify you of any changes.
                                <br /> <br />
                                These Terms incorporate a Privacy Policy as an integral part. Also, for particular services or portions of this site, we may post specific rules. In case of any conflict between the Terms and a specific rule, the specific rule will prevail.
                                <br /> <br />
                                These terms and conditions govern your use of this website at www.getEasy Connect.com or any sub domain that stems from it. By using this website, you accept these terms and conditions in full. If you disagree with these terms and conditions or any part of these terms and conditions, you must not use this website. <br /> <br />
                                You must be at least 18 years of age to use this website. By using this website and by agreeing to these terms and conditions you warrant and represent that you are at least 18 years of age.
                                <br /> <br />
                                This website uses cookies. By using this website and agreeing to these terms and conditions, you consent to our use of cookies in accordance with the terms of Easy Connect's privacy policy. </p>
                            <h1 className='privacy-heading'>Personal and Noncommercial Use Limitations</h1>
                            <p className='privicy-paragrah'> This site is for your personal and noncommercial use. You may not modify, copy, distribute, transmit, display, perform, reproduce, publish, license, create derivative works from, transfer or sell any information, software, products or services obtained from this site. You specifically agree not to access (or attempt to access) the content of the site through any automated means (including the use of any script, web crawler, robot, spider, or scraper), and that you will not forge or manipulate identifiers in order to disguise the origin of any access (or attempted access) to the site.</p>
                            <h1 className='privacy-heading'>Use of E-mails, Live Chat, and SMS</h1>
                            <p className='privicy-paragrah'>
                                You agree to use communication methods available by Easy Connect only to send messages and material that are proper and related to the subject matter for which this communication method was made available. By way of example, and not as a limitation, you agree that when using an e-mail, chat or other communication method made available on this site, you will not do any of the following:
                                <br /> <br />                                         Defame, abuse, harass, stalk, threaten or otherwise violate the legal rights (such as rights of privacy and publicity) of others.   <br /> <br />
                                Publish, post, distribute or disseminate any defamatory, infringing, obscene, indecent, false, untruthful or unlawful material or information;
                                Advertise or offer to sell any goods or services or conduct or forward surveys, contests or chain letters.   <br /> <br />
                                You acknowledge that all forums are public and not private communications. Further, you acknowledge that communications to or from service providers are not endorsed by us and such communications shall not be considered reviewed, screened, or approved by us.
                            </p>
                            <h1 className='privacy-heading'>License to use website</h1>
                            <p className='privicy-paragrah'>
                                Unless otherwise stated, Easy Connect and/or its licensors own the intellectual property rights in the website and material on the website. Subject to the license below, all these intellectual property rights are reserved. <br /> <br />
                                You may view, download for caching purposes only, and print pages from the website for your own personal use, subject to the restrictions set out below and elsewhere in these terms and conditions.
                            </p>
                            <h1 className='privacy-heading'>You must not:</h1>
                            <p className='privicy-paragrah'>
                                <ul>
                                    <li> Republish material from this website (including republication on another website);</li>
                                    <li>sell, rent or sub-license material from the website;</li>
                                    <li>show any material from the website in public;</li>
                                    <li>reproduce, duplicate, copy or otherwise exploit material on this website for a commercial purpose;</li>
                                    <li>edit or otherwise modify any material on the website; or</li>
                                    <li>redistribute material from this website except for content specifically and expressly made available for redistribution.</li>
                                    <li>Where content is specifically made available for redistribution, it may only be redistributed within your organization or with Easy Connect's consent.</li>
                                </ul>
                            </p>
                            <h1 className='privacy-heading'>Copyright and Trademark Information</h1>
                            <p className='privicy-paragrah'>
                                This website, and the information which it contains, is the property of Easy Connect and its affiliates and licensors, and is protected from unauthorized copying and dissemination by United States copyright law, trademark law, international conventions and other intellectual property laws.
                            </p>
                            <h1 className='privacy-heading'>Limitations of liability</h1>
                            <p className='privicy-paragrah'>
                                Easy Connect will not be liable to you (whether under the law of contact, the law of torts or otherwise) in relation to the contents of, or use of, or otherwise in connection with, this website: to the extent that the website is provided free-of-charge, for any direct loss; for any indirect, special or consequential loss; or for any business losses, loss of revenue, income, profits or anticipated savings, loss of contracts or business relationships, loss of reputation or goodwill, or loss or corruption of information or data. These limitations of liability apply even if Easy Connect has been expressly advised of the potential loss.
                            </p>
                            <h1 className='privacy-heading'>Exceptions</h1>
                            <p className='privicy-paragrah'>
                                Nothing in this website disclaimer will exclude or limit any warranty implied by law that it would be unlawful to exclude or limit; and nothing in this website disclaimer will exclude or limit Easy Connect liability in respect of any: death or personal injury caused by Easy Connect negligence; fraud or fraudulent misrepresentation on the part of Easy Connect; or matter which it would be illegal or unlawful for Easy Connect to exclude or limit, or to attempt or purport to exclude or limit, its liability.
                            </p>
                            <h1 className='privacy-heading'>Reasonableness</h1>
                            <p className='privicy-paragrah'>
                                By using this website, you agree that the exclusions and limitations of liability set out in this website disclaimer are reasonable. If you do not think they are reasonable, you must not use this website.
                            </p>
                            <h1 className='privacy-heading'>Other parties</h1>
                            <p className='privicy-paragrah'>
                                You accept that, as a limited liability entity, Easy Connect has an interest in limiting the personal liability of its officers and employees. You agree that you will not bring any claim personally against Easy Connect's officers or employees in respect of any losses you suffer in connection with the website. <br /> <br />
                                Without prejudice to the foregoing paragraph, you agree that the limitations of warranties and liability set out in this website disclaimer will protect Easy Connect's officers, employees, agents, subsidiaries, successors, assigns and sub-contractors as well as Easy Connect as a company. <br /> <br />
                                Service and product offerings made available for purchase by you on this site are made and offered directly by the applicable service provider. When you purchase any product or service through Easy Connect, you are contracting directly with the service provider that will be providing that product or service to you. Easy Connect facilitates your transaction with service providers. You are not purchasing or ordering products or services from Easy Connect when you order a service. Although we are interested in receiving feedback regarding our service provider and their products and services, and may from time to time assist you in your dealings with such service providers, Easy Connect is not responsible for the performance of any service provider. Easy Connect does not make any representation about the suitability for the information, software, products or services contained on this site for any purpose. All such information, software, products and services are provided “as is” without warranty of any kind. Easy Connect hereby disclaims all warranties and conditions with regard to this information, software, products and services, including, without limitation, all implied warranties and conditions of merchantability, fitness for a particular purpose, title and non-infringement. In no event shall we be liable for any direct, indirect, punitive, incidental, special or consequential damages arising out of or in any way connected with the use of this site or with any delay or inability to use this site, or for any information, software, products or services obtained through this site, or otherwise arising out of the use of this site, whether based on contract, tort, strict liability or otherwise, even if we have been advised of the possibility of damages.
                            </p>
                            <h1 className='privacy-heading'>Prices and Availability</h1>
                            <p className='privicy-paragrah'>
                                Prices and availability of products and services are subject to change without notice. We reserve the right to revoke any offering and to correct any errors, inaccuracies or omissions, which may occur. You have no obligation to use this website or to contract with the service providers whose services are offered on the website. Service providers are not obligated to offer their products or services on the website. We may not make available on the website all service providers capable of offering products and services at your location.
                            </p>
                            <h1 className='privacy-heading'>Unenforceable provisions</h1>
                            <p className='privicy-paragrah'>
                                If any provision of this website disclaimer is, or is found to be, unenforceable under applicable law, that will not affect the enforceability of the other provisions of this website disclaimer.
                            </p>
                            <h1 className='privacy-heading'>Indemnity</h1>
                            <p className='privicy-paragrah'>
                                You hereby indemnify Easy Connect and undertake to keep Easy Connect indemnified against any losses, damages, costs, liabilities and expenses (including without limitation legal expenses and any amounts paid by Easy Connect to a third party in settlement of a claim or dispute on the advice of Easy Connect's legal advisers) incurred or suffered by Easy Connect arising out of any breach by you of any provision of these terms and conditions, or arising out of any claim that you have breached any provision of these terms and conditions. Breaches of these terms and conditions without prejudice to Easy Connect's other rights under these terms and conditions, if you breach these terms and conditions in any way, Easy Connect may take such action as Easy Connect deems appropriate to deal with the breach, including suspending your access to the website, prohibiting you from accessing the website, blocking computers using your IP address from accessing the website, contacting your internet service provider to request that they block your access to the website and/or bringing court proceedings against you.
                            </p>
                            <h1 className='privacy-heading'>Variation</h1>
                            <p className='privicy-paragrah'>
                                Easy Connect may revise these terms and conditions from time-to-time. Revised terms and conditions will apply to the use of this website from the date of the publication of the revised terms and conditions on this website. Please check this page regularly to ensure you are familiar with the current version.
                            </p>
                            <h1 className='privacy-heading'>Assignment</h1>
                            <p className='privicy-paragrah'>
                                Easy Connect may transfer, sub-contract or otherwise deal with Easy Connect's rights and/or obligations under these terms and conditions without notifying you or obtaining your consent. You may not transfer, sub-contract or otherwise deal with your rights and/or obligations under these terms and conditions.
                            </p>
                            <h1 className='privacy-heading'>Severability</h1>
                            <p className='privicy-paragrah'>
                                If a provision of these terms and conditions is determined by any court or other competent authority to be unlawful and/or unenforceable, the other provisions will continue in effect. If any unlawful and/or unenforceable provision would be lawful or enforceable if part of it were deleted, that part will be deemed to be deleted, and the rest of the provision will continue in effect.
                            </p>
                            <h1 className='privacy-heading'>Entire agreement</h1>
                            <p className='privicy-paragrah'>
                                These terms and conditions, together with any specific applicable terms (if required), constitute the entire agreement between you and Easy Connect in relation to your use of this website, and supersede all previous agreements in respect of your use of this website.
                            </p>
                            <h1 className='privacy-heading'>General</h1>
                            <p className='privicy-paragrah'>
                                No advice given from Easy Connect shall create any warranty not expressly made within these Terms & Conditions. <br /> <br />
                                These terms and conditions will be governed by and construed in accordance with the state of New York within the United States of America, and any disputes relating to these terms and conditions will be subject to the exclusive jurisdiction of the courts of New York. <br /> <br />
                                In return for providing the Easy Connect service, you acknowledge Easy Connect may be paid a fee based on the services you select, part of which may be paid to a partner, agent, or property manager.
                            </p>

                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default TermCondition