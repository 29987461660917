import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';

import $ from 'jquery';
import {BrowserRouter, Switch, Route, Routes} from "react-router-dom";
import {popper} from 'popper.js'
import 'bootstrap/dist/js/bootstrap.bundle.min'
import {SatoshiRegular} from './View/Assets/Fonts/OTF/SatoshiRegular.otf'
// import {SatoshiVariable} from './View/Assets/Fonts/Variable/SatoshiVariable.ttf'
import './View/Assets/Css/Custom.css';
import NavbarCom from './View/Component/NavbarCom'
import FotterCom from './View/Component/FotterCom'
import HeaderCom from './View/Component/HeaderCom';
import Home from './View/Frontend/Home';
import Aboutus from './View/Frontend/Aboutus';
import Faqs from './View/Frontend/Faqs';
import Privacy from './View/Frontend/Privacy';
import TermCondition from './View/Frontend/TermCondition';
import Pattner from './View/Frontend/Pattner';
import Contact from './View/Frontend/Contact';
import LoginPage from './View/Frontend/LoginPage';
import RegisterPage from './View/Frontend/RegisterPage';
// import '../src/View/Assets/Css/accordian.css'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
  <div className='pattner-background'>
  <BrowserRouter>
  <NavbarCom />
          <Routes>
              <Route path="/" element={<Home />}/>
              <Route path="/about" element={<Aboutus />} />
              <Route path="/faqs" element={<Faqs />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/terms" element={<TermCondition />} />
              <Route path="/pattner" element={<Pattner />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/register" element={<RegisterPage />} />
          </Routes>
          <FotterCom />
      </BrowserRouter>
   {/* <NavbarCom /> */}
    {/* <App /> */}
    {/* <FotterCom /> */}
    </div>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
