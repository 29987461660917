import React from 'react'
import { Col, Container, Row } from 'reactstrap'
import Collapseup from '../Component/Collapseup'
import Contactus from '../Component/Contactus'
import HeaderCom from '../Component/HeaderCom'

function Faqs() {
  return (
    <div>
        <HeaderCom 
             name="Frequently"
             secname="Asked Questions"
                leftname="Home"
                rightname="FAQS"
           
        />
        <section className='py-4'>
            <Container>
                <Row>
                    <Col md={12} sm={12}>
                    <Collapseup 
                        number="1"
                        name="SOUND LIKE A LOT? "
                        desc=" In that case, you can just call Easy Connect, which continuously scans the market for suppliers that can offer you a good deal/service, and arranges your connections for you, all in one call and for free."
                    />
                    <Collapseup 
                        number="2"
                        name="HOW FAST CAN YOU CONNECT MY ELECTRICITY?"
                        desc="We can connect your electricity the same day. Sometimes, it takes a few business days for the account to register in your name, but we always try to ensure there is no interruption in the power supply and the lights are on the minute you step into your new place."
                    />
                    <Collapseup 
                        number="3"
                        name="WHICH UTILITIES DO RENTERS NORMALLY PAY?"
                        desc="Depending on which state you’re living in, your responsibilities for paying utilities can vary. Most landlords require tenants to pay for electricity, gas, internet among others. Usually water and/or sometimes heat ( which may be gas or electric) is included in the rent. Since this is not fixed, our specialists will be able to organize all of this information for you through your real estate broker, property management company and local utility, saving you the hassle."
                    />
                    <Collapseup 
                        number="4"
                        name="ARE YOU GOING TO SELL MY INFORMATION?"
                        desc="Easy Connect never sells any information to anyone. We use the information purely for signing up to the utilities that are needed for your new home. We use SOC 2 encrypted software and encrypted communications to put in our orders to ensure all date is safe and sound."
                    />
                    <Collapseup 
                        number="5"
                        name="DO YOU DISCONNECT ANY OLD SERVICES?"
                        desc="We will provide this service when the companies we work with allow us to do so. Most of the providers will request the customer do this due to safety reasons. The companies may want to ensure that any outstanding balances are satisfied. In many cases we are able to do disconnects if you are reconnecting/transferring service with the same company. If we cannot complete any request due to privacy or legal issues, we will at least walk you through how to do it yourself. "
                    />
                    
                    
                    </Col>
                </Row>
            </Container>
        </section>
       <Contactus />
    </div>
  )
}

export default Faqs