import React from 'react'
import { Container, Row, Col, Form, FormGroup, Input, Label, Button} from 'reactstrap'
import arrow from '../Assets/Images/arrow.png'

function Contactus() {
  return (
    <div>
      <section className='contact-background py-5'>
    <Container>
        <Row>
            <Col md={12} sm={12}>
            <h1 className='contact-heading py-4'>Contact US</h1>
            </Col>
        </Row>
       <Form>
       <Row>
            <Col md={6} sm={12}>
            <FormGroup className='shadow'>
          <Input type="text" name="email"  placeholder="First name" />
        </FormGroup>
            </Col>
            <Col md={6} sm={12}>
            <FormGroup className='shadow'>
          <Input type="text" name="email"  placeholder="Last name" />
        </FormGroup>
            </Col>
        </Row>
        <Row>
            <Col md={6} sm={12}>
            <FormGroup className='shadow'>
          <Input type="email" name="email"  placeholder="Enter email address" />
        </FormGroup>
            </Col>
            <Col md={6} sm={12}>
            <FormGroup className='shadow'>
          <Input type="number" name="email"  placeholder="Enter Phone number" />
        </FormGroup>
            </Col>
        </Row>
        <Row>
            <Col md={6} sm={12}>
            <FormGroup className='shadow'>
          <Input type="text" name="email" placeholder="Move in/New Address" />
        </FormGroup>
            </Col>
            <Col md={6} sm={12}>
            <FormGroup className='shadow'>
          <Input type="date" name="email" placeholder="Moving date" />
        </FormGroup>
            </Col>
        </Row>
        <Row>
            <Col md={12} sm={12}>
            <FormGroup className='shadow'>
         
          <Input type="textarea" row="5" name="text" id="exampleText" className='textarea-sy' placeholder='Let’s talk now!' />
        </FormGroup>
            </Col>
        </Row>
        <Row>
          <Col md={12} sm={12}>
            <p className='text-center py-3 color-contact'>*By submitting your information, you give us consent to contact you via phone/email and your contact <br /> information is subject to and protected by our privacy policy and terms of service.</p>
            <div className='text-center'>
            <Button className='all-btn shadow'>Send Message <img src={arrow} alt='arrow' className='foterarrow-btn' /> </Button>
            </div>
          </Col>
        </Row>
       </Form>
    </Container>
      </section>
    </div>
  )
}

export default Contactus