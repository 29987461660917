import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import HeaderCom from '../Component/HeaderCom'

function Privacy() {
    return (
        <div>
            <HeaderCom
                name="Privacy Policy"
                secname=""
                date="Last update: 12 January 2022"
                leftname="Home"
                rightname="PRIVACY POLICY"
            />
            <Container className='py-5'>
                <Row>
                    <Col md={12} sm={12}>
                        <div>
                            <h1 className='privacy-heading'>Overview</h1>
                            <p className='privicy-paragrah'>Easy Connect LLC, (“the “Company”) is committed to protecting the privacy of individuals who visit the Company’s Web sites (“Visitors”) and individuals who register to use the Services as defined below (“Customers”). This Privacy Statement describes Easy Connect's privacy practices in relation to the use of the Company’s Web sites and the related applications and services offered by Easy Connect. By accessing or using our Website, you agree to this Privacy Policy. </p>
                            <h1 className='privacy-heading'>Information Collected</h1>
                            <p className='privicy-paragrah'>When expressing an interest in obtaining additional information about the Services or registering to use the Services, the Company requires you to provide the Company with personal contact information, such as name, company name, address, phone number, and email address (“Required Contact Information”). When utilizing the Services, Easy Connect may require you to provide the Company with financial qualification and billing information, such as billing name and address, credit card number, social security number, and additional contact information regarding setting up the Services (“Billing Information”). This is solely for the purpose of enrolling in these utilities such as water, gas, home security, and internet and will not be sold or shared with any other providers without the consent of the Customer. <br />
                            <br />
                                As you navigate the Company’s Web sites, Easy Connect may also collect information through the use of commonly-used information-gathering tools, such as cookies and Web beacons (“Web Site Navigational Information”). Web Site Navigational Information includes standard information from your Web browser (such as browser type and browser language), your Internet Protocol (“IP”) address, and the actions you take on the Company’s Web sites (such as the Web pages viewed and the links clicked).</p>
                            <h1 className='privacy-heading'>Use of Information Collected</h1>
                            <p className='privicy-paragrah'>The Company uses Data About My Utilities Customers to perform the services requested. For example, if you fill out a “Contact Me” Web form, the Company will use the information provided to contact you about your interest in the Services.
                            <br /> <br />
Easy Connect uses billing and credit card information solely to establish utility services on behalf of its Customers. Easy Connect does not permanently store sensitive information such as credit card numbers, data of birth, social security number or other personal information on its servers and uses Soc-2 compliant software to use such data in it's enrollment processes. We retain information that we collect about you or that you provide to us, including any Personal Information, for business purposes and as long as is reasonably necessary to provide you with our products and services. We will also retain the information as reasonably necessary to comply with our legal obligations, resolve disputes and enforce our agreements.
<br /><br />
Easy Connect uses Web Site Navigational Information to operate and improve the Company’s Web sites. The Company may also use Web Site Navigational Information alone or in combination with Data About Easy Connect Customers and Data About My Utilities Attendees to provide personalized information about the Company. </p>
<h1 className='privacy-heading'>Your rights regarding your personal information</h1>
<p className='privicy-paragrah'>
Under applicable law, you have certain rights and choices regarding the Personal Information you provide to us:

<ul>
    <li>You Can Set Your Browser to Refuse All or Some Browser Cookies. If you disable or refuse cookies, some parts of the Website may then be inaccessible or not function properly.</li>
    <li>
        
Opt-Out of Receiving Our Marketing Emails. To stop receiving our promotional emails please click on the opt-out in the email or contact us. This opt-out does not apply to transactional or informational emails (e.g., our communication in response to your request for information, products, or services from us).
    </li>
</ul>
</p>

                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Privacy