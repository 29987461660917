import React from 'react'
import { Container, Row, Col, Button } from 'reactstrap'

function HeaderCom(props) {
  return (
    <div>
      <section className='py-5'>
        <Container>
          <Row>
            <Col md={6} sm={12}>
              <div>
                <h1 className='header-textstyle'>{props.name} <br />{props.secname} </h1>
                <p className='head-paratexr'>{props.date}</p>
              </div>
            </Col>
            <Col md={6} sm={12}>
<div className='text-end'>
<Button className='fotter-btn-header mt-4 shadow'><a href='#' className='hom-btn'>{props.leftname}</a><a href='#' className='ms-3 btn-homi'>{props.rightname}</a> </Button>
</div>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  )
}

export default HeaderCom