import React from 'react'
import { Link } from 'react-router-dom'
import { Card, CardBody, Col, Container, Form, Row, FormGroup, Input, Button } from 'reactstrap'
import arrow from '../Assets/Images/arrow.png'

function LoginPage() {
    return (
        <div>
            <Container className='my-5'>
                <Row className='justify-content-center'>
                    <Col md={9} sm={12}>
                        <Card className='py-5 login-card shadow'>
                            <CardBody>
                                <div>
                                    <h1 className='login-heading text-center'>Welcome Back</h1>
                                    <p className='text-center heading-paragrah py-3'>Please sign in below to access your partner portal</p>
                                    
                                </div>
                                <Form>
                                    <Row className='justify-content-center'>
                                        <Col md={7} sm={12}>
                                            <FormGroup className='shadow'>
                                                <Input type="email" name="email" placeholder="Enter email address" />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row className='justify-content-center py-2'>
                                        <Col md={7} sm={12}>
                                            <FormGroup className='shadow'>
                                                <Input type="password" name="email" placeholder="Enter email address" />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row className='justify-content-center '>
                                        <Col md={7} sm={12}>
                                            <div className='d-flex'>
                                                <Col>
                                                    <FormGroup>
                                                        <Input type="checkbox" /><span className='ms-2'>Remember Me</span>
                                                    </FormGroup>
                                                </Col>
                                                <Col>
                                                    <Link to="" className='login-forgot'>Forgot Password?</Link>
                                                </Col>
                                            </div>
                                        </Col>

                                    </Row>
                                    <Row className='justify-content-center'>
                                        <Col md={7} sm={12}>
                                            <Link to="/" className='d-block  py-3 text-center                                                                                '>
                                                <Button className='all-btn shadow px-5'>Login <img src={arrow} alt='arrow' className='foterarrow-btn' /> </Button>
                                            </Link>
                                        </Col>
                                    </Row>
                                    <Row className='justify-content-center'>
                                        <Col md={12} sm={12}>
                                            <div >
                                                <p className='text-center d-block'>Not registered? <Link to="/register" className='login-lin'>Register here</Link></p>
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default LoginPage