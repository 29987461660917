import React from 'react'
import { Container, Row, Form, FormGroup, Col, Input, Button } from 'reactstrap'
import HeaderCom from '../Component/HeaderCom'
import arrow from '../Assets/Images/arrow.png'
import contact from '../Assets/Images/contact.png'

function Contact() {
    return (
        <div>
            <HeaderCom
                name="Contact us"
                secname=""
                date=""
                leftname="Home"
                rightname="CONTACT US"
            />
            <Container className='py-5'>
                <Row>
                    <Form>
                        <Row>
                            <Col md={6} sm={12}>
                                <FormGroup className='shadow'>
                                    <Input type="text" name="email" placeholder="First name" />
                                </FormGroup>
                            </Col>
                            <Col md={6} sm={12}>
                                <FormGroup className='shadow'>
                                    <Input type="text" name="email" placeholder="Last name" />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6} sm={12}>
                                <FormGroup className='shadow'>
                                    <Input type="email" name="email" placeholder="Enter email address" />
                                </FormGroup>
                            </Col>
                            <Col md={6} sm={12}>
                                <FormGroup className='shadow'>
                                    <Input type="number" name="email" placeholder="Enter Phone number" />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6} sm={12}>
                                <FormGroup className='shadow'>
                                    <Input type="text" name="email" placeholder="Move in/New Address" />
                                </FormGroup>
                            </Col>
                            <Col md={6} sm={12}>
                                <FormGroup className='shadow'>
                                    <Input type="date" name="email" placeholder="Moving date" />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} sm={12}>
                                <FormGroup className='shadow'>

                                    <Input type="textarea" row="5" name="text" id="exampleText" className='textarea-sy' placeholder='Let’s talk now!' />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} sm={12}>
                                <p className='text-center py-3 color-contact'>*By submitting your information, you give us consent to contact you via phone/email and your contact <br /> information is subject to and protected by our privacy policy and terms of service.</p>
                                <div className='text-center'>
                                    <Button className='all-btn shadow'>Send Message <img src={arrow} alt='arrow' className='foterarrow-btn' /> </Button>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Row>
                <section className='py-5'>
                    <Row>
                        <Col md={12} sm={12}>
                            <div>
                                <h1 className='contact-headingtio'>After you enter your details:</h1>
                            </div>
                            <div className='pt-4'>
                                <img src={contact} alt="contact" width="100%" />
                            </div>

                        </Col>
                    </Row>
                    <Row>
                        <Col md={4} sm={12}>
                            <div>
                                <p className='contact-paragset contact-marginl '>We’ll call to <br />
                                    understand your needs <br />
                                    and discuss options</p>
                            </div>
                        </Col>
                        <Col md={4} sm={12}>
                            <div>
                                <p className='contact-paragset text-center contact-margin'>After you choose, we’ll <br />
                                    arrange the connections <br />
                                    for you</p>
                            </div>
                        </Col>
                        <Col md={4} sm={12}>
                            <div>
                                <p className='contact-paragset text-end contact-marginl'>You can move in <br />
                                    with greater peace <br />
                                    of mind!</p>
                            </div>
                        </Col>
                    </Row>
                </section>
            </Container>
        </div>
    )
}

export default Contact