import React from 'react'
import { Link } from 'react-router-dom'
import { Card, CardBody, Col, Container, Form, Row, FormGroup, Input, Button } from 'reactstrap'
import arrow from '../Assets/Images/arrow.png'

function RegisterPage() {
    return (
        <div>
            <Container className='my-5'>
                <Row className='justify-content-center'>
                    <Col md={10} sm={12}>
                        <Card className='py-5 px-4 login-card shadow'>
                            <CardBody>
                                <div>
                                    <h1 className='login-heading text-center'>Register</h1>
                                    <p className='text-center heading-paragrah py-3'>Sign up below to become a referral partner for Easy Connect</p>

                                </div>
                                <Form>
                                    <Row>
                                        <Col md={4} sm={12}>
                                            <FormGroup className='shadow'>
                                                <Input type="text" name="email" placeholder="First name" className='register-border' />
                                            </FormGroup>
                                        </Col>
                                        <Col md={4} sm={12}>
                                            <FormGroup className='shadow'>
                                                <Input type="text" name="email" placeholder="Last name" className='register-border' />
                                            </FormGroup>
                                        </Col>
                                        <Col md={4} sm={12}>
                                            <FormGroup className='shadow'>
                                                <Input type="text" name="email" placeholder="Company name" className='register-border' />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row className='py-3'>
                                        <Col md={6} sm={12}>
                                            <FormGroup className='shadow'>
                                                <Input type="text" name="email" placeholder="Enter email address" className='register-border' />
                                            </FormGroup>
                                        </Col>
                                        <Col md={6} sm={12}>
                                            <FormGroup className='shadow '>
                                                <Input type="number" name="email" placeholder="Enter Phone number" className='register-border' />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12} sm={12}>
                                            <FormGroup className='shadow'>
                                                <Input type="textarea" row="5" name="text" id="exampleText" className='textarea-sy register-border' placeholder='Let’s talk now!' />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <div className=' text-center py-3'>
                                        <Link to="/login">
                                        <Button className='all-btn shadow'>Create an account <img src={arrow} alt='arrow' className='foterarrow-btn' /> </Button>
                                        </Link>
                                    </div>
                                    <Row className='justify-content-center'>
                                        <Col md={12} sm={12}>
                                            <div >
                                                <p className='text-center d-block'>Allready Register <Link to="/login" className='login-lin'>Login here</Link></p>
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>

    )
}

export default RegisterPage